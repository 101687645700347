@import '../../styles-common/variables';

.container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 34px;
  background-color: $color-primary-dark;
  border-radius: 12px;
}

.submit {
  max-width: 300px;
  margin: 0 auto;
  margin-top: 34px;
}

.title {
  margin: 0 0 40px;
  font-size: $font-size-title-medium;
  color: $color-secondary-light;
  text-align: center;
}

.error {
  color: $color-red;
  margin-top: 10px;
}
