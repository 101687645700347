@import '../../styles-common/variables';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  border-radius: 6px;
  border: none;
  background-color: $color-green;
  color: $color-white-gold;
  font-size: $font-size-basic;
  transition: background-color 0.3s;

  &:hover:enabled,
  &:active:enabled {
    background-color: darken($color-green, 10%);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.children_container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
