@import '../../styles-common/variables';


.container {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
}

.segment {
  width: 10%;
  border-right: 1px solid $color-display-grid;
  font-size: $font-size-small;
  color: $color-display-grid;
  display:table-cell;
  vertical-align: bottom;
  padding: 4px;
}

.segment:last-child {
  border: none;
}

.segment_right {
  composes: segment;
  text-align: right;
}
