@import '../../styles-common/variables';

.container {
  position: relative;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 300px;
  min-height: 360px;
  background-color: $color-primary-dark;
  margin-bottom: 3px;
}

.slider_container {
  :global {
    .rangeslider-vertical {
      height: 300px;
      max-width: 25px;
      background-color: $color-secondary-dark !important;
      box-shadow: inset 6px 0px 10px -1px rgba(0, 0, 0, 0.6);

      border-radius: 4px;
      margin: 0 auto;

      .rangeslider__labels {
        width: 0px;
      }
    }
    .rangeslider__fill {
      background-color: transparent !important;
      width: 80px !important;
    }
    .rangeslider__handle {
      width: 60px !important;
      height: 80px !important;
      left: -17px !important;
      background-color: $color-secondary-dark !important;
      background-image: url('./Stripes.svg') !important;
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: 2px 10px 10px 0px rgba(0, 0, 0, 0.3),
        -2px 0px 10px 0px rgba(0, 0, 0, 0.3) !important;
      border: 1px solid transparent !important;
      border-radius: 6px;
    }
  }
}

// .slider_panel {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 15px;
//   margin: 0 30px;
//   margin-bottom: 20px;
//   width: 80%;
//   // background-color: $color-primary-lighter;
//   border-radius: 12px;
//   width: 100%;
// }

.display_value {
  position: absolute;
  top: 20px;
  right: 30px;
  padding: 6px 0;
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden;
  min-width: 200px;
  font-size: 48px;
  border-radius: 6px;
  color: $color-orange;
  background-color: $color-secondary-dark;
}

.answer_button {
  padding: 16px;
  text-align: center;
  min-width: 200px;
  font-size: 18px;
  border: none;
  border-radius: 6px;
  color: $color-secondary-lighter;
  background-color: $color-secondary-dark;
}

.bubble {
  min-width: 200px;
  border: 1px solid white;
  position: absolute;
  top: 120px;
  right: 30px;
  border-radius: 6px;
  border-radius: 6px;
  z-index: 10000;
  padding: 10px;
  font-weight: 400;
  color: $color-green;
  text-align: center;
  opacity: 0;
  transform: scale(0, 0);
  transition: all 0.3s ease;

  h1 {
    padding: 0;
    margin: 0 0 10px;
    font-size: 18px;
  }

  p {
    padding: 0;
    margin: 5px 0;
    font-weight: 300;
    font-size: 14px;
  }
}

.bubble_wrong {
  composes: bubble;
  border: 2px solid $color-red;
  color: $color-red;
  opacity: 1;
  transform: scale(1, 1);
  background: none;
  :global {
    animation-name: shake;
  }
  animation-duration: 1s;
  animation-fill-mode: both;
}

.bubble_right {
  composes: bubble;
  border: 2px solid $color-green;
  color: $color-green;
  background: none;
  opacity: 1;
  transform: scale(1, 1);

  :global {
    animation-name: bounceIn;
  }
  animation-duration: 1s;
}

.slider_label {
  text-align: center;
  font-size: 24px;
  color: $color-secondary-dark;
  margin-bottom: 16px 0;
}
