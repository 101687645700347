@import '../../styles-common/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 360px;
  background-color: $color-primary-dark;
}

.loading {
  width: 110px;
  height: 60px;
  margin-bottom: 20px;

  > div {
    float: left;
    background: $color-green;
    height: 3px;
    border-radius: 50%;
    width: 10px;
    margin-right: 1px;
    display: block;
    position: relative;
    bottom: 0;
    margin-top: 35px;
    animation: loading 1.5s infinite ease-in-out;
  }

  .one {
    animation-delay: -1.5s;
  }
  .two {
    animation-delay: -1.4s;
  }
  .three {
    animation-delay: -1.3s;
  }
  .four {
    animation-delay: -1.2s;
  }
  .five {
    animation-delay: -1.1s;
  }
  .six {
    animation-delay: -1s;
  }
  .seven {
    animation-delay: -0.9s;
  }
  .eight {
    animation-delay: -0.8s;
  }
  .nine {
    animation-delay: -0.7s;
  }
  .ten {
    animation-delay: -0.6s;
  }
}

@keyframes loading {
  50% {
    height: 100%;
    margin-top: 0;
    background: $color-green;
  }
}

.title {
  color: $color-green;
  font-size: 14px;
}
