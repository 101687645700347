.container {
  position: relative;
  width: 100%;
}

.current {
  z-index: 10000002;
  position: absolute;
  bottom: 80px;
  left: 44px;

  p {
    margin: 8px 0;
  }
}
