@import '../../styles-common/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: stretch;
  overflow: hidden;
}

.content_section {
  height: 100vh;
  padding: 20px;
}

.section_title {
  text-align: center;
}

.left {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bgImg {
  display: block;
  width: auto;
  height: auto;
}

.right {
  width: 40%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: $color-primary-dark;

  p {
    font-size: 22px;
    line-height: 1.3;
    margin-top: 10px;
    margin-bottom: 40px;
    // color: #f7f2da;
  }

  .logo_box {
    width: 65%;
  }
}

.link_box {
  width: 50%;
}

.try {
  position: relative;
  display: block;
  height: 100px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  line-height: 1;
  font-size: 36px;
  color: $color-orange;
  cursor: pointer;
  transform: translateZ(0);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.try svg {
  z-index: 1;
  position: absolute;
  fill: none;
  stroke: $color-orange;
  top: 6px;
  left: 0;
  width: 100%;

  g {
    fill: $color-orange;
    stroke: none;
  }
}

.try svg line,
.try svg polyline {
  stroke-miterlimit: 10;
}
.try svg circle {
  display: block;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.066em;
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset ease-out 0.5s;
}
.try font {
  z-index: 2;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.5em;
}
.try:hover {
  transform: translateZ(0) translateX(0.5em);
}
.try:hover svg circle {
  stroke-dashoffset: 200;
}

@media screen and (max-width: 2000px) {
  .right {
    p {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .right {
    p {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    min-height: 100vh;
  }

  .left {
    order: 1;
    width: 100%;
  }

  .right {
    order: 0;
    width: 100%;
    background: none;
    padding-top: 40px;
    p {
      font-size: 18px;
    }
  }
}
