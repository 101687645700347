@import '../../styles-common/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  min-height: 360px;
  background-color: $color-primary-dark;
  text-align: center;
  color: $color-orange;

  span {
    font-size: $font-size-basic;
  }
}

.title {
  margin: 20px 0;
  font-size: $font-size-title-large;
}

.icon {
  display: block;
  font-size: 48px;
  width: 200px;
  height: 280px;
  padding: 0;
  margin: 0 auto;

  svg {
    width: 100%;
  }
}

.description {
  padding: 0;
  font-size: 18px;
  margin: 0;
  margin-bottom: 20px;
}

.button {
  display: inline-block;
  margin: 0 20px;
  padding: 15px;
  min-width: 180px;
  outline: none;
  background: none;
  border: 2px solid $color-orange;
  border-radius: 6px;
  cursor: default;
  text-align: center;
  text-decoration: none;
  font-size: $font-size-basic;
  color: $color-orange;
  line-height: 1;
  transition: all 0.35s ease;

  &:hover {
    color: $color-orange-lighter;
    border-color: $color-orange-lighter;
    transform: scale3d(1.1, 1.1, 1.1);
  }
}

.donate {
  margin-top: 20px;
}

.donateLink {
  font-size: 24px;
  color: $color-orange;
  &:hover {
    color: $color-orange-lighter;
    transform: scale3d(1.1, 1.1, 1.1);
  }
}
