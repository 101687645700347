@import '../../styles-common/variables';

.container {
  position: relative;
  width: 40px;
  height: 40px;
  // border: 1px solid $color-secondary-light;
  border-radius: 50%;
  overflow: hidden;
}

.img {
  display: block;
  width: 100%;
  height: 100%;
}

.edit {
  position: absolute;
  bottom: 10%;
  left: 50%;
  width: 40px;
  transform: translate3d(-50%, 0, 0);
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.edit:hover {
  opacity: 0.4;
}
