@import '../../styles-common/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 360px;
  background-color: $color-primary-dark;
  color: $color-orange;
}

.icon {
  font-size: 48px;
  margin: 0;
  padding: 0;
}
