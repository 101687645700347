@import '../../styles-common/variables';

.container {
  position: absolute;
  top: 200px;
  border: 1px solid $color-green;
  border-radius: 6px;
  z-index: 10000;
  min-width: 180px;
  padding: 10px;
  color: $color-green;
  font-weight: 300;
  text-align: center;
  opacity: 0;
  transform: scale(0, 0);
  transition: all 0.3s ease;
}

.container p {
  padding: 0;
  margin: 5px 0;
  font-weight: 300;
  font-size: 14px;
}

.result {
  padding: 0;
  margin: 0 0 10px;
  font-size: 18px;
}

.show_right {
  composes: container;
  opacity: 1;
  :global {
    animation-name: bounceIn;
  }
  animation-duration: 0.75s;
  animation-fill-mode: both;
}

.show_wrong {
  composes: show_right;
  border: 1px solid $color-red;
  color: $color-red;
  transform-origin: center;

  :global {
    animation-name: jello;
  }
  animation-duration: 1s;
  animation-fill-mode: both;
}
