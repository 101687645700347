@import '../../styles-common/variables';

.container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 340px;
  padding: 20px;
  margin-bottom: 3px;
  background-color: $color-primary-dark;
}

.filter_container {
  flex: 1 0 20%;
  height: 140px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 6px;
  position: relative;
  background-color: #262728;

  label {
    display: block;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    color: $color-secondary-light;
    width: 100%;
    height: 100%;

    span {
      position: absolute;
      top: 15px;
      left: 15px;
      z-index: 1;
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  input {
    display: none;
  }

  input:checked + .right {
    border: 2px solid $color-green;
    color: $color-green;

    :global {
      animation-name: bounceIn;
    }
    animation-duration: 1s;
  }

  input:checked + .wrong {
    border: 2px solid $color-red;
    color: $color-red;

    :global {
      animation-name: shake;
    }
    animation-duration: 0.8s;
    animation-fill-mode: both;
  }

  input:checked + label > .tooltip {
    display: block !important;
  }

  input:checked + label .bg {
    filter: blur(6px);
  }
}

.filter_container:nth-child(4n) {
  margin-right: 0px;
}

.bubble_container {
  // text-align: center;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 3;
  padding: 0;

  h1 {
    font-size: 24px;
    margin: 0;
    margin-bottom: 15px;
  }

  p {
    padding: 0;
    margin: 0;
  }
}
