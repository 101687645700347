@import '../../styles-common/variables';

$animation-name: 'pump-it-up';

@mixin keyframes($animationName) {
  @keyframes :global(#{$animationName}) {
    @content;
  }
}

@for $i from 1 through 9 {
  @include keyframes(#{$animation-name + $i}) {
    20% {
    }
    40% {
      height: random(50) + px;
    }
    60% {
      height: random(50) + px;
    }
    80% {
      height: random(50) + px;
    }
    100% {
      height: random(50) + px;
    }
  }
}

.levels {
  width: 100px;
  height: 50px;
  margin: 0 auto;
  position: relative;

  div {
    width: 3px;
    height: 50px;
    margin-left: 1px;
    display: inline-block;
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      background: $color-secondary-dark;
      width: 3px;
    }

    @for $i from 1 through 9 {
      &.level#{$i}:after {
        height: random(50) + px;
        :global {
          animation: #{$animation-name +
            $i}
            300 +
            random(500) +
            ms
            linear
            infinite
            alternate;
        }
      }
    }
  }
}
