@import '../../styles-common/variables';

.tooltip {
  font-size: 22px;
  color: $color-orange;
  position: absolute;
  top: 30px;
  left: 55%;
  border: 1px solid $color-orange;
  border-radius: 6px;
  padding: 10px;
  transition: all .5s ease;
}

.tooltip_right {
  composes: tooltip;
  left: auto;
  right: 55%;
  transition: all 1s ease;
}