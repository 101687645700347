@import '../../styles-common/variables';

.container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 360px;
  margin-bottom: 3px;
  background-color: $color-primary-dark;

  input {
    display: none;
  }

  label {
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 30px;
    width: 25%;
    border: 2px solid $color-secondary-dark;
    border-radius: 6px;
    cursor: pointer;
  }

  label:first-of-type {
    margin-left: 30px;
  }

  input:checked + .right {
    border-color: $color-green;
    color: $color-green;
    :global {
      animation-name: bounceIn;
    }
    animation-duration: 1s;
  }

  input:checked + .wrong {
    border-color: $color-red;
    color: $color-red;
    :global {
      animation-name: shake;
    }
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  input:checked + label > .tooltip {
    display: block !important;
  }

  input:checked + label .bg {
    filter: blur(5px);
  }
}

.tooltip {
  display: none;
  text-align: center;
  position: relative;
  z-index: 1000;
  top: 22px;
  transition: opacity 2s;

  p {
    margin: 5px 0;
  }
}

.value {
  text-align: center;
  position: relative;
  top: 190px;
  font-size: 20px;
  margin-bottom: 1px;
  z-index: 1001;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
