@import '../../styles-common/variables';

.game_field {
  position: relative;
  min-height: 380px;
  background-color: $color-primary-dark;
  margin-bottom: 3px;
  padding: 20px;
}

.decay_bg {
  img {
    max-width: 100%;
  }
}

.timeboxes_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -6px;
  margin-left: -6px;
}

.title {
  text-align: center;
  font-weight: normal;

  margin: 0;
  font-size: 18px;
  margin-bottom: 20px;
  padding: 0;
}

.timebox_cell {
  flex: 1 1 auto;
  padding: 6px;
}

.timebox {
  display: flex;
  min-width: 80px;

  input {
    display: none;
  }

  label {
    overflow: hidden;
    font-size: 24px;

    padding: 16px;
    width: 100%;
    text-align: center;
    border: 2px solid $color-secondary-dark;
    border-radius: 6px;
    cursor: pointer;
  }

  input:checked + .right {
    border-color: $color-green;
    color: $color-green;
    :global {
      animation-name: bounceIn;
    }
    animation-duration: 1s;
  }

  input:checked + .wrong {
    border-color: $color-red;
    color: $color-red;
    :global {
      animation-name: shake;
    }
    animation-duration: 1s;
    animation-fill-mode: both;
  }
}

.bubble {
  min-width: 200px;
  border: 1px solid white;
  position: absolute;
  bottom: 40px;
  right: 20px;
  border-radius: 6px;
  border-radius: 6px;
  z-index: 10000;
  padding: 10px;
  font-weight: 400;
  color: $color-green;
  text-align: center;
  opacity: 0;
  transform: scale(0, 0);
  transition: all 0.3s ease;

  h1 {
    padding: 0;
    margin: 0 0 10px;
    font-size: 18px;
  }

  p {
    padding: 0;
    margin: 5px 0;
    font-weight: 300;
    font-size: 14px;
  }
}

.bubble_wrong {
  composes: bubble;
  border: 2px solid $color-red;
  color: $color-red;
  opacity: 1;
  transform: scale(1, 1);
  background: none;
  :global {
    animation-name: bounceIn;
  }
  animation-duration: 1s;
  animation-fill-mode: both;
}

.bubble_right {
  composes: bubble;
  border: 2px solid $color-green;
  color: $color-green;
  background: none;
  opacity: 1;
  transform: scale(1, 1);

  :global {
    animation-name: bounceIn;
  }
  animation-duration: 1s;
}

.result {
  font-size: 24px;
  padding: 0;
  margin: 15px 0;
}

.tip {
  margin: 12px 0;
  padding: 0 16px;
  color: $color-text-footer;
  text-align: center;
}
