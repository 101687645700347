.container {
  position: fixed;
  z-index: 99000;
  display: flex;
  top: 47px;
  left: 0;
  right: 0;
  align-items: center;
  height: 30px;
  background-color: orange;
  color: white;
  padding: 0 20px;
  overflow: hidden;
}

.center_container {
  display: flex;
  height: 100%;

  align-items: center;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;

  span {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.close {
  padding: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  color: white;
  background: none;
  border: none;
  outline: none;

  svg {
    width: 100%;
    opacity: 0.8;
  }
}

@media screen and (max-width: 640px) {
  .container {
    top: -33px;
    z-index: 100000;
  }
}
