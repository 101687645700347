@import '../../styles-common/variables';

.container {
  display: flex;
  flex: 0 0 auto;
  align-items: flex-end;
  color: $color-text-footer;
  width: 100%;
  height: 160px;
  font-size: 12px;
  padding-top: 60px;
  padding-bottom: 20px;
  //   justify-content: flex-end;

  a {
    color: currentColor;
    transition: color 0.4s ease;
  }

  a:hover {
    color: $color-secondary-light;
  }
}

.footer_section {
  margin-right: 30px;
}

.contact {
  margin-right: 20px;
}

.copyright_box {
  margin-left: auto;
}

.copyright {
  font-size: 1.3em;
  vertical-align: middle;
}

.social {
  display: flex;

  .social_link {
    margin-right: 8px;
    color: currentColor;
    width: 16px;
    height: 16px;
    font-size: 16px;
  }
}

.donate {
  margin-left: 12px;
}

.donateLink {
  font-size: 12px;
}

.donateIcon {
  margin-left: 10px;
  position: relative;
  font-size: 16px;
  top: 3px;
}

.footer_section {
  p {
    margin-top: 0;
  }
}

.link_container {
  margin-top: 8px;
}
