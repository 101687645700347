@import '../../styles-common/variables';

.nav {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-primary-dark;
  padding: 0 20px;
  // margin-bottom: 60px;
}

.button_container {
  background-color: $color-primary-dark;
}

.menu_list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1000px;
}

.link_item {
  margin: 0;
  padding: 0;

  &:first-child {
    margin-right: auto;
  }
}

.link {
  display: block;
  padding: 32px 20px;
  line-height: 1;
  height: 100%;
  text-decoration: none;
  color: $color-secondary-light;
  background-color: $color-primary-dark;
  border: none;
  font-size: $font-size-basic;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  transition: background-color 0.3s ease-out, border 0.3s ease-out;

  &:hover {
    background-color: $color-secondary-dark_hover;
    cursor: pointer;
  }
}

.link_selected {
  background-color: $color-secondary-dark;
  &:hover {
    background-color: $color-secondary-dark;
  }
}

.link_logo {
  display: block;
  text-decoration: none;
}

.auth_box {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.avatar {
  margin: 0 8px;
}

.logo_container {
  margin-right: 20px;
  max-width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hamburger {
  display: none;
  margin-left: auto;
  color: $color-secondary-light;
  background: none;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  transition: background-color 0.3s ease-out;
}

.hamburger:hover {
  background-color: $color-secondary-dark_hover;
}

@media screen and (max-width: 640px) {
  .logo_container {
    display: none;
  }

  .avatar {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .nav {
    padding: 0;
  }

  .nav_open {
    composes: nav;
    background-color: transparent;
    bottom: 0;
  }

  .links_box {
    display: block;
  }

  .links_box_close {
    display: none;
  }

  .menu_list {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  .link_item {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid $color-secondary-dark_hover;

    button {
      width: 100%;
    }
  }

  .auth_box {
    flex-direction: column;
    text-align: center;
    align-items: stretch;
    margin-left: 0;

    .link_item {
      text-align: center;
    }
  }
}

.overlay_empty {
  height: 100%;
}
