@import '../../styles-common/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 360px;
  background-color: $color-primary-dark;
  text-align: center;
  color: $color-orange;
}

/* Pulse */
@keyframes pulse {
  25% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  75% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
}

.pulse {
  animation-name: pulse;
  //for some reason animation get stuck in Safari (tested on 12.0.3)
  //setting the 1s delay fixes it
  animation-delay: 1s;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  button:hover {
    transform: scale(1) !important;
  }
}
