@import '../../styles-common/variables';

.container {
  position: relative;
}

.game_title {
  text-align: center;
  color: $color-text-footer;
}

.transition_container {
  min-height: 360px;
  margin-bottom: 20px;
}

.no_mobile {
  p {
    text-align: center;
    font-size: 24px;
  }
}
