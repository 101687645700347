@import '../../styles-common/variables';

.button {
  display: inline-block;
  margin: 0 20px;
  padding: 15px;
  min-width: 180px;
  outline: none;
  background: none;
  border: 2px solid $color-orange;
  border-radius: 6px;
  cursor: default;
  text-align: center;
  text-decoration: none;
  font-size: $font-size-basic;
  color: $color-orange;
  line-height: 1;
  transition: all 0.35s ease;

  &:hover {
    color: $color-orange-lighter;
    border-color: $color-orange-lighter;
    transform: scale3d(1.1, 1.1, 1.1);
  }
}
