@import '../../styles-common/variables';

.container {
  // min-height: 340px;
  padding: 20px;
  margin-bottom: 3px;
  background-color: $color-primary-dark;
}

.param_box {
  margin-bottom: 12px;
  p {
    margin: 6px 0;
    font-size: 14px;
  }
}

.legend {
  text-align: center;
  font-size: 18px;
  margin: 0;
  margin-bottom: 18px;
}

.options_box {
  display: flex;
  justify-content: space-between;
}

.option {
  position: relative;
  width: 32%;

  input {
    display: none;
  }

  label {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.option_title {
  position: absolute;
  left: 12px;
  top: 6px;
  font-size: 16px;
  color: $color-text-footer;
  margin: 0;
}

.option_switch {
  overflow: hidden;
  margin-bottom: 12px;
  height: 100px;
  background-color: $color-secondary-dark;
  border-radius: 6px;
  color: $color-text-footer;
  transition: background-color 0.4s ease;
}

.option_playing {
  background-color: rgb(114, 114, 114);
}

.option_switch:hover {
  background-color: $color-secondary-dark_hover;
  color: grey;
}

.option_playing:hover {
  background-color: rgb(114, 114, 114);
}

.actual {
  color: $color-secondary-light;
}

.actual_right {
  color: $color-green;
}

.right {
  border: 1px solid $color-green;
  color: $color-green;
  :global {
    animation-name: bounceIn;
  }
  animation-duration: 1s;

  .actual {
    color: $color-green;
  }
}

.wrong {
  border: 1px solid $color-red;
  color: $color-red;
  :global {
    animation-name: shake;
  }
  animation-duration: 1s;
  animation-fill-mode: both;

  .actual {
    color: $color-red;
  }
}

.play_icon {
  transition: color 0.4s ease;
}

// .play_icon:hover {
//   color: $color-white-gold;
// }

.answer_button {
  display: block;
  width: 100%;
  padding: 8px;
  border: none;
  outline: 0;
  border-radius: 6px;
  background-color: $color-green;
  color: $color-white-gold;
  transition: background-color 0.4s ease;
}

.answer_button:hover {
  background-color: darken($color-green, 10%);
}

.tip {
  margin: 12px 0;
  padding: 0 16px;
  color: $color-text-footer;
  text-align: center;
}
