@import '../../styles-common/variables';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  color: $color-orange;
  background-color: $color-primary-dark;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 3px;
}

.level {
  margin-left: 40px;
}

.lives_container {
  display: flex;
  margin-right: 30px;
}

.icon {
  width: 30px;
  height: 30px;

  margin-right: 10px;

  svg {
    width: 100%;
    height: 100%;
  }
}
