.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.label {
  margin-right: 12px;
}

.icon {
  display: block;
  flex-shrink: 0;
}
