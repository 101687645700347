.logo_container {
  display: flex;
  // height: 100%;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    padding-bottom: 8px;
  }
}
