@import '../../styles-common/variables';

.container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  // min-height: 340px;
  padding: 20px;
  margin-bottom: 3px;
  background-color: $color-primary-dark;
}

.timebox {
  flex: 1 0 auto;
  min-width: 90px;
  display: flex;

  input {
    display: none;
  }

  label {
    overflow: hidden;
    font-size: 32px;
    margin-bottom: 6px;
    margin-right: 6px;
    padding: 16px;
    width: 100%;
    text-align: center;
    border: 2px solid $color-secondary-dark;
    border-radius: 6px;
    cursor: pointer;
  }

  input:checked + .right {
    border-color: $color-green;
    color: $color-green;
    :global {
      animation-name: bounceIn;
    }
    animation-duration: 1s;
  }

  input:checked + .wrong {
    border-color: $color-red;
    color: $color-red;
    :global {
      animation-name: shake;
    }
    animation-duration: 1s;
    animation-fill-mode: both;
  }
}

.bubble {
  display: none;
  border-radius: 6px;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  text-align: center;
}

.bubble_right {
  composes: bubble;
  display: block;
  color: $color-green;
  border: 2px solid $color-green;
}

.bubble_wrong {
  composes: bubble;
  display: block;
  color: $color-red;
  border: 2px solid $color-red;
}

.result {
  font-size: 24px;
  padding: 0;
  margin: 15px 0;
}

.tip {
  margin: 12px 0;
  padding: 0 16px;
  color: $color-text-footer;
  text-align: center;
}
