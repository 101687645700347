@import '../../styles-common/variables';

.container {
  position: relative;

  width: 470px;
  height: 280px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 6px;
  background-color: $color-primary-dark;

  &:hover {
    .description_box {
      transform: scale(1);
      opacity: 1;
    }

    .title {
      transform: translate3d(5px, 5px, 0);
      color: $color-secondary-light;
    }

    .background {
      transform: scale3d(1, 1, 1);

      opacity: 0.5;
      filter: blur(5px);
    }
  }
}

.background {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.4s, transform 0.4s;
  border-style: none;
  transform: scale3d(1.1, 1.1, 1.1);
}

.title {
  position: absolute;
  margin: 0 auto;
  padding: 0;
  top: 15px;
  left: 20px;
  transition: all 0.4s ease;
  color: $color-secondary-dark;
  z-index: 99;
}

.description_box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 70%;
  height: 100px;
  opacity: 0;
  transform: scale3d(1.2, 1.2, 1.2);
  transition: all 0.4s ease;
}

.description {
  text-align: center;
  color: $color-secondary-light;
}

.link {
  display: block;
  margin: 0 auto;
  width: 150px;
  padding: 6px;
  text-decoration: none;
  text-align: center;
  border: 2px solid $color-orange;
  border-radius: 4px;
  color: $color-orange;
  transition: all 0.4s ease;

  &:hover {
    color: $color-orange-lighter;
    border-color: $color-orange-lighter;
    transform: scale3d(1.1, 1.1, 1.1);
  }
}

.soon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

@media (max-width: 959px) {
  .container {
    flex-direction: column;
  }
}
