@import '../../styles-common/variables';

.container {
  display: flex;
  align-items: center;
}

.switch {
  display: flex;
  width: 70px;
  height: 30px;
  position: relative;
  margin: 0 15px;

  input {
    position: absolute;
    display: none;
  }

  label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1000;
  }
}

/*handler*/
.handler {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background: $color-secondary-dark;
  border-radius: 30px 30px 30px 30px;
  transition: all 0.3s ease;
}

.light {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  z-index: 2;
  border-radius: 50%;
  background: $color-secondary-lighter;
  transition: all 0.3s ease;
}

.light_inactive {
  composes: light;
}

/*handler to right*/
.switch input:checked ~ .handler .light {
  transform: translate3d(40px, 0, 0);
  transition: all 0.3s ease;
  border: solid 2px $color-green;
  color: $color-green;
  background-color: $color-primary-dark;
}

.switch input:checked ~ .handler .light_inactive {
  transform: translate3d(40px, 0, 0);
  transition: all 0.3s ease;
  border: solid 2px $color-red;
  background-color: $color-primary-dark;
  color: $color-red;
}

.name {
  color: $color-secondary-lighter;
}

.counter {
  display: inline-block;
  color: $color-green;
  margin-right: auto;
  margin-left: 10px;
  min-width: 50px;
}

.counter_zero {
  composes: counter;
  color: $color-red;
}
