@import '../../styles-common/variables';

.container {
  background-color: $color-secondary-dark;
  padding-top: 80px;
}

.container_center {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 140px);
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 0 20px;
}

.content {
  flex: 1 0 auto;
}

.link_contrast {
  color: white;
}
