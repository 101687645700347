$color-primary-dark: #15181a;
$color-primary-lighter: lighten($color-primary-dark, 2%); //#2b2c2e
$color-text-footer: #424242;

$color-secondary-dark: #2b2c2e;
$color-secondary-dark_hover: #363a3f;
$color-secondary-lighter: #5f4e4a;
$color-secondary-light: lighten($color-secondary-lighter, 30%); //#ad9a95
$color-table-accent: #333538;

$color-orange: #f0d079;
$color-orange-lighter: lighten($color-orange, 20%);
$color-green: #3bd636;
$color-red: #fc4c72;
$color-white-gold: #ffefdf;

$color-display-grid: #282828;

$font-size-bigger: 18px;
$font-size-basic: 16px;
$font-size-medium: 14px;
$font-size-small: 14px;
$font-size-title-large: 36px;
$font-size-title-medium: 24px;

$profile-border-radius: 18px;
