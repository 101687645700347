@import '../../styles-common/variables';

.container {
  // margin-top: 20px;
}

.list {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  list-style: none;
  margin: 0;
  padding: 0;
}

.genre_item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 6px;
  font-size: 14px;
  background-color: $color-primary-dark;
  color: $color-secondary-light;
  transition: all 0.3s ease;

  span {
    cursor: default;
  }
}

.genre_item:hover {
  background-color: $color-primary-lighter;
  color: $color-white-gold;
}

.genre_item_active {
  background-color: $color-primary-lighter;
  box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
  color: $color-orange;
  &:hover {
    color: $color-orange;
  }
}

.genre_item_disabled {
  opacity: 0.2;
}

.genre_item_disabled:hover {
  background-color: $color-primary-dark;
  color: $color-secondary-light;
}

.tooltip {
  color: $color-secondary-light !important;
}
