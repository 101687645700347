@import '../../styles-common/variables';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: $color-primary-dark;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.mute_button {
  position: absolute;
  right: 0;
}
