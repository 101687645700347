@import '../../styles-common/variables';

$color: $color-green;
$size: 39px;
$border: $size / 25;
$time: 200ms;

.container {
  position: relative;
  margin: 0 20px;
}

.checkbox {
  position: absolute;
  display: none;
}

.mute {
  width: $size;
  height: $size;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.speaker {
  width: 19px;
  stroke: $color-green;
  stroke-width: 2px;
  transition: stroke $time;
}

.wave_one {
  position: absolute;
  border: $border solid transparent;
  border-right: $border solid $color;
  border-radius: 50%;
  transition: all $time;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 50%;
  height: 50%;
}

.wave_two {
  position: absolute;
  border: $border solid transparent;
  border-right: $border solid $color;
  border-radius: 50%;
  transition: all $time;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 75%;
  height: 75%;
}

.checkbox:checked ~ .wave_one {
  border-radius: 0;
  width: 50%;
  height: 50%;
  border-width: 0 $border 0 0;
  border-right: $border solid $color-secondary-lighter;
  transform: rotate(45deg) translate3d(0, -50%, 0);
}

.checkbox:checked ~ .wave_two {
  border-radius: 0;
  width: 50%;
  height: 50%;
  border-width: 0 $border 0 0;
  border-right: $border solid $color-secondary-lighter;
  transform: rotate(-45deg) translate3d(0, 50%, 0);
}

.checkbox:checked ~ .speaker {
  stroke: $color-secondary-lighter;
  stroke-width: 2px;
}
