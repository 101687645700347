@import '../../styles-common/variables';

.input_container {
  margin-bottom: 18px;
}

.input_container:last-of-type {
  margin-bottom: 50px;
}

.label {
  display: block;
  margin-bottom: 10px;
  color: $color-secondary-light;
}

.input {
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 10px;
  background: $color-secondary-dark;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 6px;
  overflow: hidden;
  font-size: $font-size-basic;
  color: $color-white-gold;
  transition: all 0.5s ease-in-out;
}

.input:focus {
  outline: 0;
  border: 2px solid $color-secondary-light;
  background: $color-primary-dark;
  color: $color-white-gold;
}

.textArea {
  composes: input;
  resize: vertical;
  min-height: 70px;
}

.error_field {
  color: $color-red;
  font-size: $font-size-small;
}
