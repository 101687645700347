@import '../../styles-common/variables';

.container {
  position: relative;
  width: 100%;
  min-width: 300px;
  height: 297px;
  background-color: $color-primary-dark;
  margin-bottom: 3px;
  overflow: hidden;
}

.head {
  position: relative;
  height: 100%;
  width: 35%;
  z-index: 9999;
}

.head::after {
  content: '';
  display: block;
  height: 100%;
  width: 1px;
  background-color: $color-orange;
  left: 50%;
  z-index: 9999;
  position: absolute;
}

.tooltip {
  font-size: 22px;
  color: $color-orange;
  position: absolute;
  top: 30px;
  left: 55%;
  border: 1px solid $color-orange;
  border-radius: 6px;
  padding: 10px;
  transition: all 0.5s ease;
}

.tooltip_right {
  composes: tooltip;
  left: auto;
  right: 55%;
  transition: all 1s ease;
}

.canvasCont {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.grid {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.arrow {
  width: 32px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: calc(50% - 16px);

  svg {
    fill: $color-orange;
  }
}
