@import 'styles-common/variables';

html {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

*:focus {
  outline: 0;
}

body {
  margin: 0;
  min-height: 100%;
  width: 100%;
  background-color: $color-secondary-dark;
  font-family: 'Open Sans', 'Titillium Web', sans-serif;
  font-weight: 400;
  color: $color-secondary-light;
}
