@import '../../styles-common/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 360px;
  background-color: $color-primary-dark;
  color: $color-orange;
}

.anim {
  :global {
    animation: slideInBlurTop 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
  }
}

.icon {
  width: 200px;
  svg {
    width: 100%;
  }
}
